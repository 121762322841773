import api from "@/plugins/api";


const salonProvisoireModule = {
    state() {
        return {
            salonsProvisoires: [],
            historique: []
        }
    },
    getters: {
        salonsProvisoires(state) {
            return state.salonsProvisoires

        },
        historique(state) {
            return state.historique;
        }
    },
    mutations: {
        setSalonsProvisoires(state, salons) {
            state.salonsProvisoires = salons
        },
        setHistorique(state, historique) {
            state.historique = historique
        }
    },
    actions: {
        fetchSalonsProvisoires(context) {
            return api.get('/salonsProvisoires')
                .then(response => {
                    context.commit('setSalonsProvisoires', response.data)
                })
        },
        fetchHistorique(context) {
            return api.get('/historique')
                .then(response => {
                    context.commit('setHistorique', response.data)
                })
        }
    }
}

export default salonProvisoireModule