import axios from 'axios'
import store from '@/store/index.store'




const api = axios.create({
    baseURL: process.env.VUE_APP_BACK,
})

api.interceptors.request.use(config => {
    let jwt = store.getters.jwt
    if (jwt === null) {
        store.commit('initJwt', window.localStorage.getItem('jwt'))
        jwt =  store.getters.jwt
    }
    config.headers.authorization = jwt
    return config
})


export default     api
