import api from "@/plugins/api";


const propspectsModule = {
  state() {
    return {
      prospects: [],
      nombreProspects: 0,
      errors: [],
      prospectNonSynchronise: []
    }
  },
  getters: {
    prospects(state) {
      return state.prospects
    },
    errors(state) {
      return state.errors
    },
    nombreProspects(state) {
      return state.nombreProspects
    },
    prospectNonSynchronise(state) {
      return state.prospectNonSynchronise
    }
  },
  actions: {
    fetchProspects(context, options) {
      return api.get('/prospects', {params: options})
        .then(response => {
          context.commit('initProspect', response.data)
        })
    },
    fetchProspectNonSynchronise(context) {
      return api.get('/prospectsNonSynchronise')
        .then(response => {
          context.commit('setProspectNonSynchronise', response.data)
        })

    },
    fetchErrors(context) {
      return api.get('/prospectsErreur')
        .then(response => {
          context.commit('setErrors', response.data)
        })
    }
  },
  mutations: {
    initProspect(state, prospects) {
      state.prospects = []
      if (prospects.count) {
        state.nombreProspects = prospects.count
        prospects = prospects.rows
      }
      for (let i = 0; i < prospects.length; i++) {
        prospects[i].spec_id = JSON.parse(prospects[i].spec_id)
      }
      state.prospects = prospects
    },
    setProspectNonSynchronise(state, prospects) {
      state.prospectNonSynchronise = prospects
    },
    updateProspect(state, prospect) {
      let objIndex = state.prospects.findIndex((obj => obj.programme_id === prospect.programme_id));
      state.SFConfs[objIndex] = prospect
    },

    addProspect(state, prospect) {
      state.prospects.push(prospect)
    },
    deleteProspect(state, id) {
      state.prospects = state.prospects.filter(function (obj) {
        return obj.prospect_id !== id;
      });
    },
    deleteProspectBySalon(state, id) {
      let a = []
      state.prospects.map(obj => {
        if (obj.salon_id !== id) {
          a.push(obj)
        }

      });
      state.prospects = a
    },
    setErrors(state, errors) {
      state.errors = errors
    }
  },


}

export default propspectsModule
