<template>
  <div class="prgrammes">
    <v-snackbar
        v-model="snackbar"
        color="success"
        :timeout="3000"
        top
    >
      <v-icon>mdi-check</v-icon>
      Modification effectuée
    </v-snackbar>
    <v-row class="ma-4">
      <v-col cols="12" sm="12">
        <v-card class="mx-auto" rounded>
          <v-card-title>Programmes
            <v-spacer></v-spacer>
            <v-btn small color="success" @click="openAddDialog('programme')">
              Ajouter
            </v-btn>
          </v-card-title>
          <v-data-table
              :headers="progHeaders"
              :items="programmes"
          >
            <template v-slot:[`item.actions`]="{item}">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openEditDialog(item, 'programme')" class="editIcon">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon class="deleteIcon" @click="openDeleteDialog(item, 'programme')">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </template>

          </v-data-table>


        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-4">
      <v-col cols="12" sm="12">
        <v-card class="mx-auto" rounded>
          <v-card-title>Produits
            <v-spacer></v-spacer>
            <v-btn small color="success" @click="openAddDialog('produit')">
              Ajouter
            </v-btn>
          </v-card-title>
          <v-data-table
              :headers="sfConnfsHeaders"
              :items="sfConfs"
          >
            <template v-slot:[`item.actions`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openShowDialog(item)" class="showIcon">
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Details</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openEditDialog(item, 'produit')" class="editIcon">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" v-if="$store.getters.user.role === 'admin'">
                  <v-btn v-bind="attrs" v-on="on" icon class="deleteIcon" @click="openDeleteDialog(item, 'produit')">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </template>
          </v-data-table>


        </v-card>
      </v-col>
    </v-row>
    <ShowDialog :opened="showDialog" @setOpened="setDialogOpenStatus" :item="selectedSpec" :type="'produit'"></ShowDialog>
    <EditDialog :opened="editDialog" @setOpened="setDialogEditStatus" :item="editedItem" :type="editType"
                :programmes="programmes" @editDone="editDone"></EditDialog>

    <AddDialog :opened="addDialog" @setOpened="setDialogAddStatus" :type="addType"></AddDialog>
    <DeleteDialog v-if="itemToDelete" :opened="deleteDialog" :itemName="itemToDeleteName"
                  @setOpened="setDialogDeleteStatus" @confirm="deleteProspect"></DeleteDialog>
  </div>
</template>

<script>
import ShowDialog from "@/components/Dialogs/ShowDialog";
import EditDialog from "@/components/Dialogs/EditDialog";
import AddDialog from "@/components/Dialogs/AddDialog";
import DeleteDialog from "@/components/Dialogs/DeleteDialog";
import api from "@/plugins/api";


export default {
  name: "ProgrammeView",
  components: {
    ShowDialog,
    EditDialog,
    AddDialog,
    DeleteDialog
  },
  data() {
    return {
      progHeaders: [
        {text: "Nom", align: "start", sortable: false, value: "nom"},
        {text: "Nombre de produits", value: "nbSFConfs", sortable: false, align: "center"},
        {text: "Actions", value: "actions", sortable: false, align: "end"},
      ],
      sfConnfsHeaders: [
        {text: "Nom", align: "start", sortable: false, value: "name"},
        {text: "Programme", value: "programme", align: "center"},
        {text: "Proprietaire", value: "owner_email", align: "center"},
        {text: "Actions", value: "actions", sortable: false, align: "end"},
      ],
      showDialog: false,
      showType: null,
      editDialog: false,
      selectedSpec: null,
      editType: null,
      editedItem: null,
      snackbar: false,
      addDialog: false,
      addType: '',
      deleteDialog: false,
      itemToDelete: null,
      itemToDeleteName: null,
      deleteType: ''
    }
  },
  computed: {
    programmes() {
      return this.$store.getters.programmes
    },
    sfConfs() {
      let a = []
      let sf = this.$store.getters.sfConfs
      for (let i = 0; i < sf.length; i++) {
        let item = sf[i]
        let prog = this.programmes.filter(x => x.id === sf[i].programme_Id)[0]
        if (prog)
          item.programme = prog.nom
        a.push(item)
      }
      return a;
    }
  },
  methods: {
    setDialogOpenStatus(status) {
      this.showDialog = status
    },
    openShowDialog(spec) {
      this.selectedSpec = spec
      this.setDialogOpenStatus(true)
    },
    setDialogEditStatus(status) {
      this.editDialog = status
    },
    openEditDialog(item, type) {
      let that = this
      this.editedItem = item
      this.editType = type
      // setTimeout(() => {
      that.setDialogEditStatus(true)
      // }, 1000)
    },
    editDone() {
      this.snackbar = true
    },
    setDialogAddStatus(status) {
      this.addDialog = status
    },
    openAddDialog(type) {
      this.addType = type
      this.setDialogAddStatus(true)
    },
    openDeleteDialog(item, type) {
      this.deleteType = type
      if (type === 'produit') {
        this.itemToDelete = item
        this.itemToDeleteName = item.name
      } else {
        this.itemToDelete = item
        this.itemToDeleteName = item.nom
      }
      this.setDialogDeleteStatus(true)
    },
    setDialogDeleteStatus(status) {
      this.deleteDialog = status
    },
    deleteProspect() {
      let that = this
      if (this.deleteType === 'produit') {
        api.post(process.env.VUE_APP_BACK  +'/deleteSFConf', {id: this.itemToDelete.id}).then(function () {
          api.get(process.env.VUE_APP_BACK  + '/getAllSFConfs', {}).then(function (res) {
            that.$store.commit('initSfConfs', res.data)
          }).catch(function (error) {
            console.error(error)
          })
          that.setDialogDeleteStatus(false)
        }).catch(function (error) {
          console.error(error)
        })
      } else {
        api.post(process.env.VUE_APP_BACK  +'/deleteProgramme', {id: this.itemToDelete.id}).then(function () {
          that.setDialogDeleteStatus(false)
          api.get(process.env.VUE_APP_BACK  + '/programmes', {}).then(function (res) {
            that.$store.commit('initProgrammes', res.data)
          }).catch(function (error) {
            console.error(error)
          })

        }).catch(function (error) {
          console.error(error)
        })
      }

    }
  }
}
</script>

<style scoped>

</style>