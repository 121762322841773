<template>
  <v-dialog v-model="opened" width="500" scrollable>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Selectionnez la campagne à associer :
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-treeview
                :items="campaignSelection.selects"
                :load-children="handleCampaignSelection"
                item-key="Id"
                item-text="Name"
                item-children="children"
                open-on-click
                dense
                hoverable
                activatable
                :active.sync="selectedCampaignId"
            ></v-treeview>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-chip color="#004F91" dark class="mr-4" v-if="selectedCampaignName !==''">
          <v-icon small class="mr-2">mdi-file-account</v-icon>
          {{ selectedCampaignName }}
        </v-chip>
        <v-btn color="primary" text @click="linkSalonProv()" :disabled="selectedCampaignId.length !== 1 "> Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean
  },
  emits: ['setOpened', 'linkSalonProv'],
  data() {
    return {
      opened: this.dialog,
      campaignSelection: {
        selects: [],
        loading: false
      },
      selectedCampaignId: [],
      selectedCampaignName: ''
    };
  },
  watch: {
    dialog(newVal) {
      this.opened = newVal;
    },
    opened(newVal) {
      this.$emit('setOpened', newVal);
    }
  },
  methods: {
    async handleCampaignSelection(item) {
      if (item.isLeaf) {
        return;
      }
      if (!item.children || item.children.length === 0) {
        this.campaignSelection.loading = true;
        try {
          const children = await this.$store.dispatch('fetchCampaigns', item.Id);
          if (children.length > 0) {
            this.$set(item, 'children', children);
          } else {
            this.$set(item, 'isLeaf', true);
            this.$set(item, 'children', null);
            this.selectedCampaignId = [item.Id];
            this.selectedCampaignName = item.Name;
          }
        } catch (error) {
          console.error("Failed to fetch children for item", item.Id, error);
        } finally {
          this.campaignSelection.loading = false;
        }
      }
    },
    async fetchCampaigns() {
      this.campaignSelection.selects = await this.$store.dispatch('fetchCampaigns');
    },
    linkSalonProv() {
      this.$emit('linkSalonProv', {
        campaignId: this.selectedCampaignId[0],
        campaignName: this.selectedCampaignName
      });
    }
  },
  mounted() {
    this.fetchCampaigns();
  }
};
</script>

<style scoped>

</style>