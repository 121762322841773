import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProspectView from "@/views/ProspectView";
import SalonView from "@/views/SalonView";
import ProgrammeView from "@/views/ProgrammeView";
import LoginView from "@/views/LoginView";
import api from "@/plugins/api";
import store from '@/store/index.store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Accueil',
        component: HomeView
    },
    {
        path: '/homeView',
        name: 'Accueil',
        component: HomeView
    },
    {
        path: '/prospectView',
        name: "Prospects",
        component: ProspectView
    },
    {
        path: '/salonsView',
        name: "salons",
        component: SalonView
    },
    {
        path: '/programmeView',
        name: "Programmes",
        component: ProgrammeView
    }
    ,
    {
        path: '/loginView',
        name: "Authentification",
        component: LoginView
    },
    {
        path:'/adminView',
        name: "Admin",
        component: () => import('../views/AdminView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/loginView'];
    const authRequired = !publicPages.includes(to.path);
    let token = store.getters.jwt
    if (token === null) {
        token = window.localStorage.getItem('jwt')
    }

    if (authRequired) {
        api.post('/verify', {}, {
            headers: {
                authorization: token
            }
        }).then(function (res) {
            store.commit('initUser', res.data)
            next()
        }).catch(function () {
            return next('/loginView');
        })
    } else {
        next();
    }

})

export default router
