<template>
  <v-dialog v-model="isOpened" v-if="editedItem !== null" scrollable>
    <v-card>
      <v-card-title v-if="type==='produit'">Modification de : {{ editedItem.name }}</v-card-title>
      <v-card-title v-if="type==='programme'">Modification de : {{ editedItem.name }}</v-card-title>
      <v-card-title v-if="type==='salon'">Modification de : {{ editedItem.name }}</v-card-title>
      <v-card-title v-if="type==='prospect'">Modification de : {{ editedItem.civilite }}. {{ editedItem.nom }}
        {{ editedItem.prenom }}
      </v-card-title>
      <v-card-text v-if="type==='produit'">
        <v-form ref="editProduitForm">
          <v-row class="ma-1">
            <v-col cols="2" class="font-weight-bold">
              Nom :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="editedItem.name" :rules="rules.produit.default"></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="ma-1">
            <v-col cols="2" class="font-weight-bold">
              Email du proprietaire :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="editedItem.owner_email" :rules="rules.produit.default"></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-1">
            <v-col cols="2" class="font-weight-bold">
              Id SalesForce du proprietaire :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="editedItem.owner_id" :rules="rules.produit.default"></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-1 align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Identifiant Akeneo :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="editedItem.akeneo_id" :rules="rules.produit.default"></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-1">
            <v-col cols="2" class="font-weight-bold">
              Programme :
            </v-col>
            <v-col cols="10">
              <v-select v-model="editedItem.programme_Id" :rules="rules.produit.default" :items="programmes"
                        item-text="nom" item-value="id"></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-1 align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Niveau d’étude/diplôme pour info programme :
            </v-col>
            <v-col cols="10">
              <v-select v-model="editedItem.niveaux" :items="niveaux" :rules="rules.produit.select" chips multiple
                        item-text="nom"
                        item-editedItem="id"></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-1 align-center align-center">
            <v-col cols="2" class="font-weight-bold">
              Type de compte :
            </v-col>
            <v-col cols="10">
              <v-select v-model="editedItem.account_type" :items="typeDeComptes" item-text="text" item-value="value"
                        :rules="rules.produit.default"></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!--      <v-card-text v-if="type==='salon'">-->
      <!--      </v-card-text>-->
      <v-card-text v-if="type === 'prospect'">
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Civilité :
          </v-col>
          <v-col cols="10">
            <v-select :items="['M', 'MME']" v-model="editedItem.civilite"></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Nom :
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="editedItem.nom"></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Prénom:
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="editedItem.prenom"></v-text-field>
          </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Email :
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="editedItem.email"></v-text-field>
          </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Téléphone :
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="editedItem.tel"></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Code Postal :
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="editedItem.code_postal"></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Etablissement :
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="editedItem.etablissement"></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Niveau :
          </v-col>
          <v-col cols="10">
            <v-select chips :items="niveaux" v-model="editedItem.niveau" :rules="rules.produit.select"></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-2">
          <v-col cols="2" class="font-weight-bold">
            Produit :
          </v-col>
          <v-col cols="10">
            <v-select chips multiple v-model="editedItem.spec_id" :items="produits" item-text="name"
                      item-value="id"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="type==='programme'">
        <v-form ref="editProgrammeForm">
          <v-row class="ma-1">
            <v-col cols="2" class="font-weight-bold">
              Nom :
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="editedItem.nom" :rules="rules.programme"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="cancelEdit">Annuler</v-btn>
        <v-btn color="success" @click="editItem">Valider</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import api from "@/plugins/api";

export default {
  name: "EditDialog",
  props: ["opened", "type", "item", "programmes"],

  computed: {
    isOpened: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('setOpened', value)
      },
    },
    niveaux() {
      return this.$store.getters.niveaux
    },
  },
  watch: {
    isOpened(value) {
      if (value == true) {
        if (this.$refs.editProduitForm)
          this.$refs.editProduitForm.resetValidation()
        this.editedItem = {...this.item}
        if (this.type === 'prospect') {
          this.produits = this.$store.getters.sfConfs
        }
      }
    }
  },
  data() {
    return {
      typeDeComptes: [
          {text: 'Prospect SciencesCom', value: 'Prospect SciencesCom'},
        {text: 'Prospect MS', value: 'Prospect MS'},
        {text: 'Prospect BACH', value: 'Prospect ABM'},
        {text: 'Prospect GE', value: 'Prospect GE'},
      ],
      produits: [],
      editedItem: {},
      rules: {
        produit: {
          default: [value => {
            if (value) return true

            return 'Champs obligatoire.'
          }],
          select: [value => {
            if (value.length > 0 && value !== "" && value) return true

            return 'Champs obligatoire.'
          }],
        },
        programme: [value => {
          if (value) return true

          return 'Champs obligatoire.'
        }
        ]
      },
    }
  }
  ,
  methods: {
    editItem() {
      let that = this
      switch (this.type) {
        case("produit"):
          this.editedItem.niveaux = this.editedItem.niveaux.toString()
          if (this.$refs.editProduitForm.validate() === false || this.editedItem.niveaux.length === 0) return
          api.post('/updateSFConf', {spec: this.editedItem}).then(function () {
            that.$store.commit('updateSFConfs', that.editedItem)
            that.$store.dispatch('fetchSfConfs')
            that.$emit('editDone')
          })
          break;
        case("programme"):
          if (this.$refs.editProgrammeForm.validate() === false) return
          api.post('/updateProgramme', {programme: this.editedItem}).then(function () {
            that.$store.commit('updateProg', that.editedItem)
            that.$store.dispatch('fetchProgrammes')
            that.$emit('editDone')
          })
          break;
        case("salon"):
          break;
        case("prospect"):
          // eslint-disable-next-line no-case-declarations
          let prospect = this.editedItem
          prospect.spec_id = JSON.stringify(prospect.spec_id)
          api.post('/updateProspect', {prospect: prospect}).then(function () {
            that.$store.commit('updateProspect', that.editedItem)
            that.$store.dispatch('fetchProspects')
            that.$emit('editDone')
          })
          break;
      }
      this.isOpened = false
    }
    ,
    cancelEdit() {
      if (this.$refs.editProduitForm)
        this.$refs.editProduitForm.resetValidation()
      if (this.$refs.editProgrammeForm)
        this.$refs.editProgrammeForm.resetValidation()
      this.isOpened = false
    }
  }

}
</script>

<style scoped>
.v-text-field {
  margin: 0;
  padding: 0;
}

.col {
  padding: 0;
  padding-top: 1rem;
}
</style>