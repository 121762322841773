<template>
  <div class="Home">
    <v-snackbar v-model="snackbar" :color="snackbarType" :timeout="3000" top>
      <v-icon>mdi-check</v-icon>
      {{ snackbarMsg }}
    </v-snackbar>
    <v-row class="ma-4">
      <v-col cols="12">
        <v-card>
          <v-card-title>Salons à synchroniser</v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Nom</th>
                  <th class="text-left">
                    Nombre de prospects à synchroniser
                  </th>

                  <th class="text-left">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in salonsProvisoires" :key="item.nom">
                  <td>{{ item.nom }}</td>
                  <td>{{ item.nombrePro }}</td>
                  <td>
                    <v-btn dark color="#004f91" @click="openLinkDialog(item)"
                    >Synchroniser
                    </v-btn
                    >
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-4">
      <v-col cols="6" sm="6">
        <v-card class="mx-auto" rounded>
          <v-card-title>Historique de synchronisation</v-card-title>
          <v-data-table
            :headers="headers"
            :items="historique.slice().reverse()"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ format(new Date(item.createdAt), "DD/MM/YYYY - HH:mm") }}
            </template>
            <template v-slot:[`item.statut`]="{ item }">
              <v-tooltip v-if="item.statut === 'pending'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-progress-circular
                    v-bind="attrs"
                    v-on="on"
                    indeterminate size="20"
                    color="primary"
                  ></v-progress-circular>
                </template>
                <span>Export en cours</span>
              </v-tooltip>
              <v-tooltip v-if="item.statut === 'pending with errors'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-progress-circular
                    v-bind="attrs"
                    v-on="on"
                    indeterminate size="20"
                    color="warning"
                  ></v-progress-circular>
                </template>
                <span>Export en cours, avec erreur(s)</span>
              </v-tooltip>
              <v-tooltip v-if="item.statut === 'finished'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                  >mdi-check
                  </v-icon>
                </template>
                <span>Export terminé</span>
              </v-tooltip>
              <v-tooltip v-if="item.statut === 'finished with errors'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                  >mdi-alert
                  </v-icon>
                </template>
                <span>Erreur(s) lors de l'export</span>
              </v-tooltip>

            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="6" sm="6">
        <v-card class="mx-auto" rounded>
          <v-card-title>Campagnes</v-card-title>
          <v-data-table
            :headers="campagneHeaders"
            :items="campagnes.slice().reverse()"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <loading-dialog
      class="loadingDialog"
      :opened="loadingOpened"
    ></loading-dialog>
        <LinkDialog
            :dialog="dialog"
            @setOpened="dialog = $event"
            @linkSalonProv="linkSalonProv"
        ></LinkDialog>
<!--    <LegacyLinkDialog-->
<!--      :dialog="dialog"-->
<!--      @setOpened="dialog = $event"-->
<!--      @selectSalonSF="selectedSalon = $event"-->
<!--      @setDateSalon="dateSalon = $event"-->
<!--      @linkSalonProv="legacyLinkSalonProv"></LegacyLinkDialog>-->
  </div>
</template>

<script>
// @ is an alias to /src


import LoadingDialog from "@/components/Dialogs/LoadingDialog";
import {format} from "fecha";
import * as Sentry from "@sentry/vue";
import api from "@/plugins/api";
import LinkDialog from "@/components/Dialogs/LinkDialog.vue";
// import LegacyLinkDialog from "@/components/Dialogs/LegacyLinkDialog.vue";

export default {
  name: "HomeView",
  components: {
    // LegacyLinkDialog,
    LinkDialog,
    LoadingDialog,
  },
  async mounted() {
    await this.$store.dispatch('fetchSalonsProvisoires')
    await this.$store.dispatch('fetchProgrammes')
    await this.$store.dispatch('fetchProspects')
    await this.$store.dispatch('fetchProspectNonSynchronise')
    await this.$store.dispatch('fetchSfConfs')
    await this.$store.dispatch('fetchHistorique')
  },
  computed: {
    salonsProvisoires: function () {
      let output = [];

      let p = this.$store.getters.prospectNonSynchronise;
      if (p.length === 0) return [];
      output = p.filter((x) => x.statut_SF === false && x.salon_SF === null && x.salon_provisoire !== null && x.en_erreur === false).map((x) => {
        return {
          nom: x.salon_provisoire,
          nombrePro: p.filter((y) => y.salon_provisoire === x.salon_provisoire && y.statut_SF === false).length,
        };
      }).filter((x, i, self) => self.findIndex((y) => y.nom === x.nom) === i);
      return output;
    },
    historique: function () {
      return this.$store.getters.historique;
    },
    campagnes: function () {
      let output = [];

      let p = this.$store.getters.prospects;
      if (p.length === 0) return [];
      output = p.filter((x) => x.salon_SF !== null).map((x) => {
        return {
          nom: x.salon_SF,
          nombrePro: p.filter((y) => y.salon_SF === x.salon_SF).length,
          nombreProErr: p.filter((y) =>( y.salon_SF === x.salon_SF && y.statut_SF === false)).length,
        };
      }).filter((x, i, self) => self.findIndex((y) => y.nom === x.nom) === i);
      return output;
    },
  },
  data() {
    return {
      dialog: false,
      salonProvisoireTable: {
        headers: [
          {
            text: "Nom",
            align: "start",
            sortable: false,
            value: "nom",
          },
          {
            text: "Nombre de prospects à synchroniser",
            align: "start",
            sortable: false,
            value: "nombrePro",
          },
          {
            text: "Actions",
            align: "end",
            sortable: false,
            value: "name",
          },
        ],
      },
      selectedProv: null,
      selectedSalon: null,
      dateSalon: null,
      dateSalonMenu: false,
      loadingOpened: false,
      snackbar: false,
      snackbarMsg: "",
      snackbarType: "",
      campagneHeaders: [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "nom",
        },

        {
          text: "Nombre de prospects",
          align: "start",
          sortable: true,
          value: "nombrePro",
        },

        {
          text: "Nombre de prospects en erreur",
          align: "start",
          sortable: true,
          value: "nombreProErr",
        }
      ],
      headers: [
        {
          text: "Salon Provisoire",
          align: "start",
          sortable: true,
          value: "salon_provisoire",
        },
        {
          text: "Salon SalesForce",
          align: "start",
          sortable: true,
          value: "salon_sf",
        },
        {
          text: "Date de synchronisation",
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        {
          text: "Statut",
          align: "start",
          sortable: true,
          value: "statut",
        }
      ],
    };
  },
  methods: {
    async linkSalonProv(payload) {
      this.loadingOpened = true;

      api.post('/sfLinkSalons', {
        nom: this.selectedProv.nom,
        id_campagne: payload.campaignId,
        nom_campagne: payload.campaignName,
      }).then(() => {
        this.dialog = false;
        this.loadingOpened = false;
        this.salon = null;
        this.$store.dispatch('fetchHistorique');
      }).catch((error) => {
        console.error("Error on postLinkSalons");
        console.error(error.message);
        Sentry.captureException(error);
        this.snackbarType = "error";
        this.snackbarMsg = `Erreur lors de la synchronisation`;
        this.snackbar = true;
        this.dialog = false;
        this.loadingOpened = false;
      })
    },
    openLinkDialog(prov) {
      console.log(prov);
      this.selectedProv = prov;
      this.dialog = true;
    },


    format,
  },
};
</script>
