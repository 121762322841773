import api from "@/plugins/api";


const Module = {
    state() {
        return {
            salesforce: {
                instanceUrl: null,
                accessToken: null,
            },
            campaigns: []
        }
    },
    getters: {
        salesforce(state) {
            return state.salesforce
        },
        campaigns(state) {
            return state.campaigns
        }
    },actions: {
        async fetchCampaigns({ commit }, parentID) {
            let data = {};
            if (parentID) {
                data.parentId = parentID;
            }
            try {
                const response = await api.post('/sfGetCampaigns', data);
                if (response.data && response.data.childrens === true) {
                    let campaigns = response.data.campaigns.map(campaign => ({
                        ...campaign,
                        id: campaign.Id, // Ensure the property name matches v-treeview expectation
                        name: campaign.Name,
                        children: [] // Initialize children array for reactivity
                    }));
                    if (parentID) {
                        commit('setChildrenCampaigns', { campaigns, parentId: parentID });
                    } else {
                        commit('setCampaigns', campaigns);
                    }
                    return campaigns;
                } else {
                    return [];
                }
            } catch (error) {
                console.error("Error fetching campaigns:", error);
                // Handle error appropriately
                throw error; // Rethrow or handle as needed
            }
        }
    },
    mutations: {
        setCampaigns(state, campaigns) {
            state.campaigns = campaigns;
        },
        setChildrenCampaigns(state, { campaigns, parentId }) {
            const parentIndex = state.campaigns.findIndex(campaign => campaign.id === parentId);
            if (parentIndex !== -1) {
                // Use Vue.set for reactivity if needed, e.g., Vue.set(state.campaigns[parentIndex], 'children', campaigns);
                state.campaigns[parentIndex].children = campaigns;
            }
        }
    }
}

export default Module